<template>
  <div class="flex-button-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: "flex-button-group"
};
</script>

<style lang="scss">
.flex-button-group {
  display: flex;

  > * {
    margin-right: 10px;
  }
}
</style>
